<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="9" xl="12">
        <h5 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("USUARIOS.CPF_CNPJ") }}
        </h5>

        <input-mask
          :authStyle="true"
          :mask="['###.###.###-##', '##.###.###/####-##']"
          :placeholder="$t('PLACEHOLDER.CPF_CNPJ')"
          :label="$t('PLACEHOLDER.CPF_CNPJ')"
          ref="cpfCnpj"
          v-model="form.cpfCnpj"
          id="cpfCnpj"
          required
        />

        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("USUARIOS.NOME") }}
        </h4>

        <input-text
          ref="nome"
          v-model="form.nome"
          noLabel
          inputClass="input-autenticacao"
          :label="$t('GERAL.EMAIL')"
          :placeholder="$t('PLACEHOLDER.NOME_USUARIO')"
          required
        />

        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("GERAL.EMAIL") }}
        </h4>

        <input-text
          ref="email"
          v-model="form.email"
          noLabel
          inputClass="input-autenticacao"
          :label="$t('GERAL.EMAIL')"
          :placeholder="$t('PLACEHOLDER.EMAIL')"
          required
          @enter="gerarAcesso"
        />

        <b-button
          variant="primary"
          size="lg"
          block
          class="mt-4 input-autenticacao"
          @click="gerarAcesso"
          >{{ $t("GERAL.CADASTRAR") }}</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import InputMask from "@/components/inputs/InputMask";
import SacadoService from "@/common/services/sacado/sacado.service";
import Vue from "vue";

export default {
  name: "CadastroForm",
  components: {
    InputText,
    InputMask,
  },
  data() {
    return {
      form: {
        nome: null,
      },

      mostrarModal: false,
    };
  },
  methods: {
    validarFormulario() {
      let arValidation = [];
      let refs = this.$refs;

      for (const key in refs) {
        if (Object.hasOwnProperty.call(refs, key)) {
          arValidation.push(refs[key].valid());
        }
      }

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    gerarAcesso() {
      if (!this.validarFormulario()) return;

      this.$store.dispatch(START_LOADING);
      SacadoService.gerarAcesso(this.form)
        .then((result) => {
          console.log("result", result);
          if (result) {
            Vue.$toast.success(
              "Um e-mail foi direcionado para a caixa de entrada do e-mail informado abaixo"
            );
          }
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style lang="scss">
.input-autenticacao {
  height: 50px !important;
}
</style>
