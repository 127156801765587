<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="9" xl="12">
        <div
          class="d-md-grid align-items-center justify-content-center erro-div"
        >
          <feather type="x-circle" class="erro-icon"></feather>
          <div class="erro-msg">
            {{
              comercial
                ? $t("USUARIOS.INFORMACOES_INCORRETAS_COMERCIAL")
                : $t("USUARIOS.INFORMACOES_INCORRETAS")
            }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "LoginErro",
  props: {
    comercial: Boolean,
  },
};
</script>

<style lang="scss">
.erro-icon {
  width: 50px;
  color: red;
  font-weight: normal;
  border-radius: 100px;
  height: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: auto;
}
.erro-div {
  margin-top: 0px;
}
.erro-msg {
  font-weight: bold;
  width: 80%;
  text-align: center;
  margin: 10px auto;
}
</style>
