<template>
  <div>
    <b-row class="justify-content-center">
      <alerta-login/>
      <b-col cols="12" lg="9" xl="12">
        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("GERAL.NOVA_SENHA") }}
        </h4>
        
        <div>
          <input-text
            ref="senha"
            v-model="form.senha"
            noLabel
            type="password"
            :placeholder="$t('GERAL.NOVA_SENHA')"
            :minLength="6"
            required
          />

          <verifica-forca-senha class="mt-2" embaixo :value="forcaSenha"></verifica-forca-senha>
        </div>

        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("GERAL.CONFIRMAR_NOVA_SENHA") }}
        </h4>

        <input-text
          ref="confirmacaoSenha"
          v-model="form.confirmacaoSenha"
          noLabel
          type="password"
          :label="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
          :placeholder="$t('GERAL.CONFIRMAR_NOVA_SENHA')"
          :validate="validarSenha"
          :minLength="6"
          required
        />

        <b-button variant="primary" size="lg" block class="mt-4 input-autenticacao" @click="alterarSenha">{{ $t("GERAL.TROCAR_SENHA") }}</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import UsuarioService from "@/common/services/usuario/usuario.service";
import VerificaForcaSenha from "@/views/usuarios/components/VerificaForcaSenha.vue";
import InputText from "@/components/inputs/InputText";
import AlertaLogin from "./AlertaLogin.vue";
export default {
  name: "TrocarSenha",
  components: {
    InputText,
    AlertaLogin,
    VerificaForcaSenha,
  },
  props: {
    loginErro: Function,
  },
  data() {
    return {
      form: {
        senha: null, //mudar depois
        confirmacaoSenha: null,
      },
      mostrarModal: false,
      noError: true,
      forcaSenha : ""
    };
  },
  watch : {
    "form.senha"() {
      this.verificarForcaDaSenha();
    },
  },
  computed: {
    validarSenha() {
      return this.form.senha === this.form.confirmacaoSenha;
    },
  },
  methods: {
    verificarForcaDaSenha() {
      let senha = this.form.senha;
      UsuarioService.verificarForcaDaSenha(senha)
        .then((result) => {
          this.forcaSenha = result.data.forcaSenhaEnum;
        })
        .catch((err) => console.log(err));
    },
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.confirmacaoSenha.valid());
      arValidation.push(this.$refs.senha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    alterarSenha() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      this.form.usuarioId = localStorage.getItem('usuario_id');
      UsuarioService.alterarSenha(this.form)
        .then((result) => {
          if(result){
            this.$router.push({ name: "operacoes" })
          }
        })
        . catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirModal() {
      this.mostrarModal = true;
    },
    fecharModal() {
      this.mostrarModal = false;
    },
    saibaMais() {
      window.location.href = "https://www.valorem.com.br/antecipacao-de-recebiveis/";
    },
  },
};
</script>

<style lang="scss">
.input-autenticacao {
  height: 60px !important;
}
</style>
