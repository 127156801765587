<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="12" lg="9" xl="12">
        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("GERAL.EMAIL") }}
        </h4>

        <input-text
          ref="nomeUsuarioEmail"
          v-model="form.nomeUsuarioEmail"
          type="email"
          noLabel
          inputClass="input-autenticacao"
          :label="$t('GERAL.EMAIL')"
          :placeholder="$t('PLACEHOLDER.EMAIL_USER')"
          required
        />

        <h4 class="font-weight-normal mt-8 valorem-login valorem-label">
          {{ $t("GERAL.SENHA") }}
        </h4>

        <input-text
          ref="senha"
          v-model="form.senha"
          noLabel
          inputClass="input-autenticacao"
          type="password"
          :label="$t('GERAL.SENHA')"
          :placeholder="$t('PLACEHOLDER.SENHA')"
          @enter="login"
          required
        />

        <b-button
          variant="primary"
          size="lg"
          block
          class="mt-4 input-autenticacao"
          @click="login"
          >{{ $t("GERAL.ENTRAR") }}</b-button
        >
        <div class="ml-auto">
          <a
            @click="abrirModal"
            href="javascript:void(0)"
            class="link valorem-esqueceu-senha"
            >{{ $t("GERAL.ESQUECEU_SENHA") }}</a
          >
        </div>
      </b-col>
    </b-row>
    <modal-esqueceu-senha
      :mostrarModal="mostrarModal"
      @fecharModal="fecharModal()"
    />
  </div>
</template>
  
  <script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import InputText from "@/components/inputs/InputText";
import { LOGIN } from "@/store/autenticacao.module";
import ModalEsqueceuSenha from "./components/ModalEsqueceuSenha";

export default {
  name: "LoginComercial",
  components: {
    InputText,
    ModalEsqueceuSenha,
  },
  props: {
    loginErro: Function,
  },
  data() {
    return {
      form: {
        nomeUsuarioEmail: null, //mudar depois
        senha: null,
      },
      mostrarModal: false,
      noError: true,
    };
  },
  methods: {
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.nomeUsuarioEmail.valid());
      arValidation.push(this.$refs.senha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    login() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.form;
      this.$store
        .dispatch(LOGIN, { ...form, comercial: true })
        .then((resultado) => {
          if (resultado.precisaAlterarSenha) {
            this.$emit("precisaAlterarSenha", resultado.precisaAlterarSenha);
          } else {
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch(() => {
          this.$emit("loginErro", true);
          this.error = false;
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirModal() {
      this.mostrarModal = true;
    },
    fecharModal() {
      this.mostrarModal = false;
    },
    saibaMais() {
      window.location.href =
        "https://www.valorem.com.br/antecipacao-de-recebiveis/";
    },
  },
};
</script>
  
  <style lang="scss">
.input-autenticacao {
  height: 60px !important;
}
</style>
  