import ApiService from "@/common/api/api.service";
import helpers from "@/common/utils/helpers";

const UsuarioService = {
  objectToQueryString(obj) {
    var str = [];
    for (var p in obj)
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));

    return str.join("&");
  },
  listar(form) {
    form.Cnpj = form.Cnpj ? helpers.removerMascara(form.Cnpj) : "";
    let sendForm = this.objectToQueryString(form);
    return ApiService.get(`usuario?${sendForm}`);
  },
  buscarPorId(id) {
    return ApiService.get(`usuario/${id}`);
  },
  async salvar(form) {
    form.cnpj = helpers.removerMascara(form.cnpj);
    let result = [];
    if (!form.id) result = await ApiService.post(`usuario`, { ...form });
    else result = await ApiService.put(`usuario`, { ...form });
    return result;
  },
  excluir(id) {
    return ApiService.delete(`usuario?id=${id}`);
  },
  alterarSenha(form) {
    return ApiService.put(`usuario/alterar-senha`, { ...form });
  },
  editarPerfis(form) {
    return ApiService.put(
      `usuario/associar-perfis`,
      { ...form },
      { noToast: true }
    );
  },
  esqueceuSenha(form) {
    form.cnpj = form.cnpj ? helpers.removerMascara(form.cnpj) : "";
    return ApiService.post(
      `usuario/solicitar-reset-senha?email=${form.email}&cnpj=${form.cnpj}`,
      { ...form }
    );
  },
  resetarSenha(form) {
    return ApiService.put(`usuario/reset-senha`, { ...form });
  },
  obterToken(id) {
    return ApiService.get(`usuario/obter-reset-token/?id=${id}`);
  },
  verificarForcaDaSenha(form) {
    return ApiService.post(`trocasenhaforte/checar-senha?request=${form}`, "", {
      noToast: true,
    });
  },
  ativarInativar : function(form, ativarInativar){
    if(ativarInativar){
      return ApiService.put(`usuario/ativar`, form);
    }
    return ApiService.put(`usuario/inativar`, form);
  },
};

export default UsuarioService;
