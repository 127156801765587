<template>
  <div id="login" style="min-height: calc(100vh - 20px)" tag="section">
    <b-row class="justify-content-center no-gutters">
      <b-col lg="12" class="d-flex align-items-center">
        <b-card no-body class="w-100">
          <b-row class="no-gutters" style="min-height: 100vh">
            <b-col
              @click="saibaMais"
              lg="6"
              class="d-md-flex align-items-center justify-content-center valorem-img-bgd"
              style="border-radius: 0% 30px 30px 0%"
            >
              <div class="d-flex align-items-center">
                <div class="p-5">
                  <!-- <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="9">
                      <div>
                        <h2 class="display-7 text-white fw-medium">
                          {{ $t("GERAL.PAGINA_PRINCIPAL_TITULO") }}
                        </h2>
                        <p class="mt-4 text-white op-5 font-weight-normal">
                          {{ $t("GERAL.PAGINA_PRINCIPAL_MSG") }}
                        </p>
                        <div class="ml-auto">
                          <b-button
                            @click="saibaMais"
                            size="lg"
                            pill
                            variant="outline-light"
                            >{{ $t("GERAL.SAIBA_MAIS") }}</b-button
                          >
                        </div>
                      </div>
                    </b-col>
                  </b-row>-->
                </div>
              </div>
            </b-col>
            <b-col
              lg="6"
              class="d-md-flex align-items-center justify-content-center"
            >
              <div class="d-flex align-items-center w-100">
                <div class="p-5 w-100">
                  <b-row class="justify-content-center">
                    <b-col cols="12" lg="9" xl="8">
                      <img
                        src="@/assets/images/logo.png"
                        class="valorem-logo"
                      />

                      <!-- <login-form @loginErro="(e)=>{
                            loginErro = e;
                          }" /> -->
                      <b-tabs
                        v-model="tabIndex"
                        content-class="mt-3 tab-autenticacao"
                      >
                        <b-tab
                          class="tabs-autenticacao"
                          :title-link-class="linkClass(0)"
                          :title="$t('GERAL.ACESSO_CEDENTE')"
                        >
                          <login-erro v-if="loginErro" />
                          <trocar-senha v-if="primeiroLogin" />
                          <login-form
                            v-if="primeiroLogin == false"
                            @loginErro="
                              (e) => {
                                loginErro = e;
                              }
                            "
                            @precisaAlterarSenha="(e) => precisaAlterarSenha(e)"
                          />
                        </b-tab>
                        <b-tab
                          class="tabs-autenticacao"
                          :title-link-class="linkClass(1)"
                          :title="$t('GERAL.ACESSO_SACADO')"
                        >
                          <p>{{ $t("GERAL.SUBTITULO_ACESSO_SACADO") }}</p>
                          <cadastro-form
                            v-if="cadastroSucesso == false"
                            @cadastroSucesso="
                              (e) => {
                                cadastroSucesso = e;
                              }
                            "
                          />

                          <cadastro-sucesso v-else />
                        </b-tab>
                        <b-tab
                          class="tabs-autenticacao"
                          :title-link-class="linkClass(2)"
                          :title="$t('GERAL.ACESSO_COMERCIAL')"
                        >
                          <login-erro :comercial="true" v-if="loginErro" />
                          <trocar-senha v-if="primeiroLogin" />
                          <login-comercial
                            v-if="primeiroLogin == false"
                            @loginErro="
                              (e) => {
                                loginErro = e;
                              }
                            "
                            @precisaAlterarSenha="(e) => precisaAlterarSenha(e)"
                          />
                        </b-tab>
                      </b-tabs>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <modal-esqueceu-senha
      :mostrarModal="mostrarModal"
      @fecharModal="fecharModal()"
    />
  </div>
</template>

<script>
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import LoginForm from "./LoginForm.vue";
import TrocarSenha from "./TrocarSenha.vue";
import LoginErro from "./LoginErro.vue";
import CadastroForm from "./CadastroForm.vue";
import CadastroSucesso from "./CadastroSucesso.vue";
import { LOGIN } from "@/store/autenticacao.module";
import ModalEsqueceuSenha from "./components/ModalEsqueceuSenha";
import LoginComercial from "./LoginComercial.vue";
export default {
  name: "Login",
  components: {
    ModalEsqueceuSenha,
    LoginForm,
    LoginErro,
    TrocarSenha,
    CadastroForm,
    CadastroSucesso,
    LoginComercial,
  },
  data() {
    return {
      cadastroSucesso: false,
      loginErro: false,
      primeiroLogin: false,
      tabIndex: window.location.pathname == "/cadastro-sacado" ? 1 : 0,
      mostrarModal: false,
    };
  },
  created() {
    window.addEventListener("beforeunload", this.removeUser);
  },
  methods: {
    removeUser() {
      if (this.$router.currentRoute.path != "/login") return;
      localStorage.removeItem("usuario_token");
    },
    validarFormulario() {
      let arValidation = [];
      //arValidation.push(this.$refs.nomeUsuarioEmail.valid());
      arValidation.push(this.$refs.senha.valid());

      return arValidation.filter((elem) => elem == false).length == 0;
    },
    precisaAlterarSenha(e) {
      this.primeiroLogin = e;
    },
    login() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const form = this.form;
      this.$store
        .dispatch(LOGIN, { ...form, comercial: false })
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abrirModal() {
      this.mostrarModal = true;
    },
    fecharModal() {
      this.mostrarModal = false;
    },
    saibaMais() {
      window.location.href =
        "https://www.valorem.com.br/antecipacao-de-recebiveis/";
    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["tabs-autenticacao", "active-tab"];
      } else {
        return ["tabs-autenticacao"];
      }
    },
  },
};
</script>

<style lang="scss">
.nav-link {
  padding: 0.75rem 0.9rem;
}
.tab-autenticacao {
  min-height: 500px;
}
.active-tab {
  background-color: #a08c6a !important;
  color: white !important;
}
</style>
